import config from '../config';

export const getUserData = (userId) => {
  const BaseURL = `${config.http.BASE_URL}/resume-items?user=${userId}`;
  return fetch(BaseURL, { method: 'GET' }).then(
    (response) =>
      response.status !== 200
        ? {
            error: true,
          }
        : response
            .json()
            .then((json) => json)
            .catch(() => ({
              error: true,
            })),
    (error) => {
      throw new Error(error.message);
    },
  );
};

export default getUserData;
