import React, { useState } from 'react';
import Item from './Item';

export default function Items({itemsArr, onDelete}) {
  const [show, setShow] = useState(false)
  return itemsArr.length ?
      <div className="mb-2">
        <div className='text-base pointer flex items-center mb-2'>
          <i 
            className="material-icons relative bg-white border-2 rounded border-gray-400 hover:border-gray-500 flex flex-shrink-0 justify-center items-center focus-within:border-blue-500 cursor-pointer" 
            onClick={() => setShow(!show)}
          >
            {show ? 'expand_less' : 'expand_more'}
          </i>
          <label className="text-sm text-gray-600 ml-2 select-none">
            Hide / Expand
          </label>
        </div>
        
        {!show && itemsArr.map(
          (value, index) => <Item key={index} item={value} onDelete={onDelete} />
        )} 
      </div>
    : null
}
