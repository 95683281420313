import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AppContext from '../../../context/AppContext';
import Checkbox from '../../../shared/Checkbox';
import Item from '../../../shared/TechSkills/Item';
import AddItem from '../../../shared/TechSkills/AddItem';
import DragNDropList from '../../../shared/DragNDropList';
import config from '../../../config';
import '../../../assets/css/styles.css';

const ProgrammingLanguagesTab = ({ data, onChange }) => {
  const context = useContext(AppContext);
  const { dispatch } = context;
  const { t } = useTranslation('leftSidebar');

  useEffect(() => {
    if (!('programmingLanguages' in data)) {
      dispatch({
        type: 'migrate_section',
        payload: {
          key: 'programmingLanguages',
          value: {
            enable: false,
            heading: 'Programming Languages',
            items: [],
          },
        },
      });
    }
  }, [data, dispatch]);

  return (
    'programmingLanguages' in data && (
      <>
        <div className="mb23 flex justify-between items-center">
          <div className="title_tab">{t('programmingLanguages.title.label')}</div>

          <div className="col-span-1">
            <Checkbox
              checked={data.programmingLanguages.enable}
              onChange={(v) => onChange('data.programmingLanguages.enable', v)}
            />
          </div>
        </div>

        <hr className="my-6" />

        <DragNDropList
          data={data}
          dispatch={dispatch}
          onChange={onChange}
          type="programmingLanguages"
        >
          {data.programmingLanguages.items.map((x, index) => (
            <Item
              item={x}
              key={x.id}
              index={index}
              onChange={onChange}
              dispatch={dispatch}
              first={index === 0}
              last={index === data.programmingLanguages.items.length - 1}
              type="programmingLanguages"
              idTab={config.TECH_SKILLS_TABS.programmingLanguages}
            />
          ))}
        </DragNDropList>

        <AddItem
          heading={data.programmingLanguages.heading}
          dispatch={dispatch}
          type="programmingLanguages"
          idTab={config.TECH_SKILLS_TABS.programmingLanguages}
        />
      </>
    )
  );
};

export default ProgrammingLanguagesTab;
