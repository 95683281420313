import React, { useState } from 'react';
import { deleteItem, moveItemDown, moveItemUp } from '../../utils';
import Form from './Form';

const Item = ({ item, index, onChange, dispatch, first, last }) => {
  const identifier = `data.skills.items[${index}]`;
  const [errors, setErrors] = useState({
    value: false,
  });

  return (
    <div className="my-4 grid grid-cols-12">
      <div className="col-span-9">
        <Form item={item} onChange={onChange} identifier={identifier} errors={errors} setErrors={setErrors}/>
      </div>

      <button
        type="button"
        onClick={() => moveItemUp(dispatch, 'skills', item)}
        className={`col-span-1 ${ first ? 'text-gray-300' : 'text-gray-600' } ${ first ? '' : 'hover:text-red-600' } ${ first ? 'cursor-default' : 'cursor-pointer' } text-sm font-medium`}
        disabled={first}
      >
        <div className="flex justify-center items-center">
          <i className="material-icons font-bold text-lg">arrow_upward</i>
        </div>
      </button>

      <button
        type="button"
        onClick={() => moveItemDown(dispatch, 'skills', item)}
        className={`col-span-1 ${ last ? 'text-gray-300' : 'text-gray-600' } ${ last ? '' : 'hover:text-red-600' } ${ last ? 'cursor-default' : 'cursor-pointer' } text-sm font-medium`}
        disabled={last}
      >
        <div className="flex justify-center items-center">
          <i className="material-icons font-bold text-lg">arrow_downward</i>
        </div>
      </button>

      <button
        type="button"
        onClick={() => deleteItem(dispatch, 'skills', item)}
        className="col-span-1 text-gray-600 hover:text-red-600 text-sm font-medium"
      >
        <div className="flex justify-center items-center">
          <i className="material-icons font-bold text-lg">close</i>
        </div>
      </button>
    </div>
  );
};

export default Item;
