import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import thunk from 'redux-thunk';

import { createRootReducer } from './reducers';

export const history = createBrowserHistory()
const middleWares = [thunk, routerMiddleware(history)];

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    compose(
      applyMiddleware(
        ...middleWares
      ),
    ),
  )

  return store
}
