import config from '../config';
import { getElementFromStorage } from '../utils/storage';

export const getCVByTempLink = userId => {
  const BaseURL = `${config.http.BASE_URL}/link/${userId}`;
  const token = getElementFromStorage('jwtToken');
  return fetch(BaseURL, { 
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token === 'undefined' ? '' : token}`,
    },
  })
    .then(
      response =>
        response.status !== 200
          ? {
              error: true,
              responseStatus: response.status,
            }
          : response
              .json()
              .then(json => json)
              .catch(() => ({
                error: true,
              })),
      error => {
        throw new Error(error.message);
      },
    );
};

export default getCVByTempLink;
