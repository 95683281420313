import approvedMarkIco from '../assets/images/icons/approvedMark.png';
import notApprovedMarkIco from '../assets/images/icons/notApprovedMark.png';
import modifiedMarkIco from '../assets/images/icons/modifiedMark.png';
import awaitMarkIco from '../assets/images/icons/awaitMark.png';
import expiredMarkIco from '../assets/images/icons/expiredMark.png';
import globalIco from '../assets/images/icons/global.png';
import mortarboardIco from '../assets/images/icons/mortarboard.png';
import star from '../assets/images/icons/star.png';
import programmingIco from '../assets/images/icons/programming.png';
import databaseIco from '../assets/images/icons/database.png';
import settingsIco from '../assets/images/icons/settings.png';
import devTechIco from '../assets/images/icons/setting.png';
import ciCdToolsIco from '../assets/images/icons/tools.png';
import loopIco from '../assets/images/icons/loop.png';
import iOsIco from '../assets/images/icons/ios.png';
import androidIco from '../assets/images/icons/android.png';
import methodologyIco from '../assets/images/icons/methodology.png';
import addIco from '../assets/images/icons/add.png';
import startIco from '../assets/images/icons/start-up.png';
// import feedbackIcon from '../assets/images/icons/feedback.png';

export const dateFormat = 'dd/MM/yyyy';
export const monthYearFormat = 'MMMM yyyy';
export const shortMonthYearFormat = 'MMM yyyy';

export const companyName = 'softteco';

export const yearsList = [
  { key: 'Select years', value: '', disabled: true },
  { key: 1, value: 1 },
  { key: 2, value: 2 },
  { key: 3, value: 3 },
  { key: 4, value: 4 },
  { key: 5, value: 5 },
  { key: 6, value: 6 },
  { key: 7, value: 7 },
  { key: 8, value: 8 },
  { key: 9, value: 9 },
  { key: 10, value: 10 },
  { key: 11, value: 11 },
  { key: 12, value: 12 },
  { key: 13, value: 13 },
  { key: 14, value: 14 },
  { key: 15, value: 15 },
  { key: 16, value: 16 },
  { key: 17, value: 17 },
  { key: 18, value: 18 },
  { key: 19, value: 19 },
  { key: 20, value: 20 },
  { key: 21, value: 21 },
  { key: 22, value: 22 },
  { key: 23, value: 23 },
  { key: 24, value: 24 },
  { key: 25, value: 25 },
  { key: 26, value: 26 },
  { key: 27, value: 27 },
  { key: 28, value: 28 },
  { key: 29, value: 29 },
  { key: 30, value: 30 },
];

export const clearCvModel = {
  summary: {
    enable: true,
    heading: 'Summary',
    firstName: '',
    lastName: '',
    position: '',
    position_level: '',
    body: '',
    experience: '',
  },
  education: {
    enable: true,
    heading: 'Education',
    items: [],
  },
  languages: {
    enable: true,
    heading: 'Languages',
    items: [],
  },
  programmingLanguages: {
    enable: true,
    heading: 'Programming Lang.',
    items: [],
  },
  dataBases: {
    enable: true,
    heading: 'Databases',
    items: [],
  },
  ideOrTools: {
    enable: true,
    heading: 'IDE/Development Tools',
    items: [],
  },
  developmentTechnologies: {
    enable: true,
    heading: 'Development Technologies',
    items: [],
  },
  ciCdTools: {
    enable: true,
    heading: 'CI/CD Tools',
    items: [],
  },
  versionControlSystems: {
    enable: true,
    heading: 'Issue Tracking / Version Control System',
    items: [],
  },
  iOsTechnologies: {
    enable: true,
    heading: 'iOS Technologies',
    items: [],
  },
  androidTechnologies: {
    enable: true,
    heading: 'Android Technologies',
    items: [],
  },
  developmentMethodologies: {
    enable: true,
    heading: 'Development Methodologies',
    items: [],
  },
  other: {
    enable: true,
    heading: 'Other Skills',
    items: [],
  },
  skills: {
    enable: true,
    heading: 'Skills',
    items: [],
  },
  projects: {
    enable: true,
    heading: 'Projects',
    items: [],
  },
};

export const tabsExceptSummary = [
  { key: 'languages', icon: globalIco, title: 'Languages' },
  { key: 'education', icon: mortarboardIco, title: 'Education' },
  { key: 'skills', icon: star, title: 'Skills' },
  { key: 'programmingLanguages', icon: programmingIco, title: 'Programming Languages' },
  { key: 'dataBases', icon: databaseIco, title: 'Databases' },
  { key: 'ideOrTools', icon: settingsIco, title: 'IDE or Development Tools' },
  { key: 'developmentTechnologies', icon: devTechIco, title: 'Development Technologies' },
  { key: 'ciCdTools', icon: ciCdToolsIco, title: 'CI/CD Tools' },
  {
    key: 'versionControlSystems',
    icon: loopIco,
    title: 'Issue Tracking / Version Control Systems',
  },
  { key: 'iOsTechnologies', icon: iOsIco, title: 'iOS Technologies' },
  { key: 'androidTechnologies', icon: androidIco, title: 'Android Technologies' },
  { key: 'developmentMethodologies', icon: methodologyIco, title: 'Development Methodologies' },
  { key: 'other', icon: addIco, title: 'Other Skills' },
  { key: 'projects', icon: startIco, title: 'Projects' },
  // { key: 'feedback', icon: feedbackIcon, title: 'Feedback' }
];

export const langLevels = [
  { key: 'Select level', value: '', disabled: true },
  { key: 'Elementary', value: 'Elementary' },
  { key: 'Pre-Intermediate', value: 'Pre-Intermediate' },
  { key: 'Intermediate', value: 'Intermediate' },
  { key: 'Upper-Intermediate', value: 'Upper-Intermediate' },
  { key: 'Full Professional Proficiency', value: 'Full Professional Proficiency' },
  { key: 'Native', value: 'Native' },
];

export const getMarkIcon = data => {
  switch (data.cvStatus) {
    case 'approved':
      return approvedMarkIco;
    case 'notApproved':
      return notApprovedMarkIco;
    case 'modified':
      return modifiedMarkIco;
    case 'expired':
      return expiredMarkIco;
    case 'awaitingApproval':
      return awaitMarkIco;
    default:
      return notApprovedMarkIco;
  }
};

export function isValidDatePickerDate(date) {
  const isDate = new Date(date).toString() !== 'Invalid Date';
  const isNull = date === null;
  return isDate || isNull;
}

export const sortProjects = data => {
  const compareFunc = (a, b) => {
    // month & year comparison
    function compareDate(x, y) {
      let result = 0;
      if (x.getFullYear() !== y.getFullYear()) {
        result = x.getFullYear() - y.getFullYear();
      } else {
        result = x.getMonth() - y.getMonth();
      }
      return result;
    }

    const dateAStart = new Date(a.start);
    const dateBStart = new Date(b.start);
    const dateAEnd = a.end ? new Date(a.end) : new Date();
    const dateBEnd = b.end ? new Date(b.end) : new Date();
    const endCompare = compareDate(dateBEnd, dateAEnd);
    let result = 0;
    if (endCompare) {
      result = endCompare;
    } else {
      result = compareDate(dateBStart, dateAStart);
    }
    return result;
  };

  const dataCopy = JSON.parse(JSON.stringify(data));
  dataCopy.projects.items.sort(compareFunc);
  return dataCopy;
};

export const isEqualProjects = (projectsItemsArray1, projectsItemsArray2) => {
  let equal = true;
  if (JSON.stringify(projectsItemsArray1) !== JSON.stringify(projectsItemsArray2)) {
    if (projectsItemsArray1.length !== projectsItemsArray2.length) {
      equal = false;
    } else {
      const filter = projectsItemsArray1?.filter((value, index) => {
        return !(
          value.start === projectsItemsArray2[index].start &&
          value.end === projectsItemsArray2[index].end
        );
        // projectsItemsArray2[index].start , if moving projects IS a condition to sort
        // projectsItemsArray2.find(v => v.start === value.start).start , if moving projects is NOT a condition to sort
      });
      if (filter.length) {
        equal = false;
      }
    }
  }
  return equal;
};
