import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AppContext from '../../../context/AppContext';
import Checkbox from '../../../shared/Checkbox';
import Item from '../../../shared/Other/Item';
import AddItem from '../../../shared/Other/AddItem';
import DragNDropList from '../../../shared/DragNDropList';

const OtherTab = ({ data, onChange }) => {
  const context = useContext(AppContext);
  const { dispatch } = context;
  const { t } = useTranslation('leftSidebar');

  useEffect(() => {
    if (!('other' in data)) {
      dispatch({
        type: 'migrate_section',
        payload: {
          key: 'other',
          value: {
            enable: true,
            heading: 'other',
            items: [],
          },
        },
      });
    }
  }, [data, dispatch]);

  return (
    'other' in data && (
      <>
        <div className="mb23 flex justify-between items-center">
          <div className="title_tab">{t('other.title.label')}</div>

          <div className="col-span-1">
            <Checkbox
              checked={data.other.enable}
              onChange={v => onChange('data.other.enable', v)}
            />
          </div>
        </div>

        <hr className="my-6" />

        <DragNDropList
          data={data}
          dispatch={dispatch}
          onChange={onChange}
          type="other"
        >
          {data.other.items.map((x, index) => (
            <Item
              item={x}
              key={x.id}
              index={index}
              onChange={onChange}
              dispatch={dispatch}
              first={index === 0}
              last={index === data.other.items.length - 1}
            />
          ))}
        </DragNDropList>


        <AddItem heading='Other Skill' dispatch={dispatch} />
      </>
    )
  );
};


export default OtherTab;
