import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AppContext from '../../../context/AppContext';
import Checkbox from '../../../shared/Checkbox';
import Item from '../../../shared/Projects/Item';
import AddItem from '../../../shared/Projects/AddItem';
import DragNDropList from '../../../shared/DragNDropList';

const ProjectsTab = ({ data, onChange }) => {
  const context = useContext(AppContext);
  const { dispatch } = context;
  const { t } = useTranslation('leftSidebar');

  useEffect(() => {
    if (!('projects' in data)) {
      dispatch({
        type: 'migrate_section',
        payload: {
          key: 'projects',
          value: {
            enable: false,
            heading: 'Projects',
            items: [],
          },
        },
      });
    }
  }, [data, dispatch]);

  return (
    'projects' in data && (
      <>
        <div className="mb23 flex justify-between items-center">
          <div className="title_tab">{t('projects.title.label')}</div>

          <div className="col-span-1">
            <Checkbox
              checked={data.projects.enable}
              onChange={v => onChange('data.projects.enable', v)}
            />
          </div>

        </div>

        <hr className="my-6"/>

        <DragNDropList
          data={data}
          dispatch={dispatch}
          onChange={onChange}
          type="projects"
        >
          {data.projects.items.map((x, index) => (
            <Item
              item={x}
              key={x.id}
              index={index}
              onChange={onChange}
              dispatch={dispatch}
              first={index === 0}
              last={index === data.projects.items.length - 1}
            />
          ))}        
        </DragNDropList>


        <AddItem heading={data.projects.heading} dispatch={dispatch} />
      </>
    )
  );
};

export default ProjectsTab;
