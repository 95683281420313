import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '../TextField';
import TextArea from '../TextArea';

const Form = ({ item, onChange, identifier = '', errors, setErrors }) => {
  const { t } = useTranslation('leftSidebar');

  return (
    <div>
      <TextField
        className="mb-6"
        label={t('experience.name.label')}
        placeholder={t('experience.name.placeholder')}
        value={item.key}
        star="true"
        validation={errors.key}
        onChange={v => {
          onChange(`${identifier}key`, v);
          setErrors({
            key: false,
          });
        }}
      />

      <TextArea
        rows="15"
        className="mb-6"
        label={t('experience.description.label')}
        value={item.body}
        placeholder={t('experience.description.placeholder')}
        onChange={v => onChange(`${identifier}body`, v)}
      />
    </div>
  );
};

export default Form;
