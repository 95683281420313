import React, { useState } from 'react';
import ItemHeadingWithDeleteButton from '../ItemHeadingWithDeleteButton';
import ItemActions from '../ItemActions';
import Form from './Form';
import { isValidDatePickerDate } from '../../helpers/AppHelpers';

const Item = ({ item, index, onChange, dispatch, first, last }) => {
  const [isOpen, setOpen] = useState(false);
  const type = 'projects';
  const identifier = `data.${type}.items[${index}].`;

  const isValidStartMessage = isValidDatePickerDate(item.start) ? null : 'Invalid date format';
  const isValidEndMessage = isValidDatePickerDate(item.end) ? null : 'Invalid date format';

  const validationErrors = {
    start : item.start ? isValidStartMessage : 'Start Date field is required',
    end : isValidEndMessage,
  };

  return (
    <div className="my-4 border border-gray-200 rounded">
      <ItemHeadingWithDeleteButton setOpen={setOpen} isOpen={isOpen} item={item} dispatch={dispatch} type={type}/>

      <div className={`${isOpen ? 'block' : 'hidden'} px-5 pb-5`}>
        <Form item={item} onChange={onChange} identifier={identifier}  validationErrors={validationErrors}/>

        <ItemActions
          dispatch={dispatch}
          first={first}
          identifier={identifier}
          item={item}
          last={last}
          onChange={onChange}
          type={type}
        />
      </div>
    </div>
  );
};

export default Item;
