import { toast } from 'react-toastify';

const toastParameters = {
  position: toast.POSITION.BOTTOM_LEFT,
  autoClose: 6000,
};

const successToast = (message = 'Success! Changes have been saved') => {
  toast.success(message, toastParameters);
};

const errorToast = (message = 'Something is wrong! Please, try later.') => {
  toast.error(message, toastParameters);
};

const returnChangesToast = () => {
  toast.info('Unsaved changes have been discarded', toastParameters);
};

const infoToast = (message) => {
  toast.info(message, toastParameters);
};

const warnToast = (message) => {
  toast.warn(message, toastParameters)
}

export {
  successToast,
  errorToast,
  returnChangesToast,
  infoToast,
  warnToast
};
