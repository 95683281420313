import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '../TextField';
import Dropdown from '../Dropdown';
import Checkbox from '../Checkbox';

const Form = ({ item, onChange, identifier = '', errors, setErrors }) => {
  const { t } = useTranslation('leftSidebar');

  const expLevel = [
    { key: 'Select level', value: '', disabled: true },
    { key: 'Basic', value: `${t('other.basic.label')}` },
    { key: 'Advanced', value: `${t('other.advanced.label')}` },
    { key: 'Expert', value: `${t('other.expert.label')}` },
  ];

  return (
    <div>
      <TextField
        className="mb-6"
        label={t('other.name.label')}
        placeholder={t('other.name.placeholder')}
        value={item.key}
        star="true"
        validation={errors.key}
        onChange={v => {
          onChange(`${identifier}key`, v);
          setErrors({
            key: false,
            years: errors.years,
          });
        }}
      />

      <div className="mb-6">
        <TextField
          className="mb-1"
        label={t('other.years.label')}
        placeholder={t('other.years.placeholder')}
        value={item.years}
        star="true"
        validation={errors.years}
        onChange={v => {
          onChange(`${identifier}years`, v);
          setErrors({
            key: errors.key,
            years: false,
          });
        }}
        />
        <Checkbox
          checked={item.notActive}
          onChange={v => onChange(`${identifier}notActive`, v)}
          size="1rem"
          label={t("other.checkBox.label")}
        />
      </div>

      <Dropdown
        label={t('other.level.label')}
        value={item.level}
        options={expLevel}
        className="mb-6"
        onChange={v => onChange(`${identifier}level`, v)}
        optionItem={x => (
          <option key={x.key} value={x.value} selected={x.disabled} disabled={x.disabled}>
            {x.disabled ? "Select level" : x.value}
          </option>
        )}
      />
    </div>
  );
};

export default Form;
