import app from './app';
import leftSidebar from './leftSidebar';
import rightSidebar from './rightSidebar';
import login from './login';
import header from './header';
import shared from './shared';

export default {
  app,
  leftSidebar,
  rightSidebar,
  login,
  header,
  shared
};
