import { useTranslation } from 'react-i18next';
import React from 'react';

const Form = ({ item, onChange, identifier = '', errors, setErrors }) => {
  const { t } = useTranslation('leftSidebar');
  return (
    <input
      className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${errors.value ? 'border-red-400' : 'border-gray-200'} rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
      placeholder={t('skills.input.placeholder')}
      value={item.value}
      onChange={v => {
        onChange(`${identifier}value`, v.target.value);
        setErrors({
          value: false,
        });
      }}
      type="text"
    />
  );
};

export default Form;
