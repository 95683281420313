import { useTranslation } from 'react-i18next';
import React from 'react';
// import { get, remove } from 'lodash';
// import AppContext from '../../context/AppContext';
// import AutoComplete from '../AutoComplete';
import TextField from '../TextField';
import TextArea from '../TextArea';
// import config from '../../config';
import Checkbox from '../Checkbox';
import DatePicker from '../DatePicker';
import { shortMonthYearFormat } from '../../helpers/AppHelpers';

const Form = ({
  item,
  onChange,
  identifier = '',
  onProjectSelect,
  validationErrors,
  setValidationErrors,
}) => {
  const { t } = useTranslation('leftSidebar');

  // const context = useContext(AppContext);
  // const { state } = context;
  // const { data } = state;

  // const [open, setOpen] = React.useState(false);
  // const [options, setOptions] = React.useState([]);
  // const loading = open;
  // React.useEffect(() => {
  //   let active = true;

  //   if (!loading) {
  //     return undefined;
  //   }

  //   (async () => {
  //     const response = await fetch(
  //       `${config.http.BASE_URL}/Autocomplete-project-fields?_limit=1000`,
  //     );
  //     let projects = await response.json();
  //     projects = projects.sort((a, b) => get(a, 'title').localeCompare(get(b, 'title')));
  //     projects = remove(
  //       projects,
  //       language =>
  //         !data.projects.items.some(itemFromArray => {
  //           return get(language, 'title').toLowerCase() === get(itemFromArray, 'key').toLowerCase();
  //         }),
  //     );
  //     if (active) {
  //       setOptions(projects);
  //     }
  //   })();

  //   return () => {
  //     active = false;
  //   };
  // }, [loading, data.projects.items]);

  // const defaultValue = options.find(project => get(project, 'title') === item.key) || null;

  return (
    <div>
      {/* <AutoComplete
        label={t('projects.autoComplete.label')}
        value={defaultValue}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={options}
        disabled={item.disabledAC}
        onChange={(v) => {
          if (onProjectSelect) {
            onProjectSelect(v);
          }
        }}
        placeholder={t('projects.autoComplete.placeholder')}
        getOptionLabel={(option) => get(option, 'title')}
      /> */}

      {/* <div className="event_line">
        <span className="event__hide_label">{t('projects.line.label')}</span>
      </div> */}

      <div className="mb-6">
        <TextField
          className="mb-1"
          label={t('projects.shortDescription.label')}
          placeholder={t('projects.shortDescription.placeholder')}
          value={item.alternativeName}
          validation={!!validationErrors?.alternativeName}
          star="true"
          onChange={v => onChange(`${identifier}alternativeName`, v)}
        />
        {!!validationErrors?.alternativeName && (
          <span className="text-red-500 text-sm block leading-1">
            {validationErrors.alternativeName}
          </span>
        )}
      </div>

      <div className="mb-6">
        <TextField
          className="mb-1"
          classNameInput={item.disabled}
          label={t('projects.name.label')}
          placeholder={t('projects.name.placeholder')}
          value={item.key}
          disabled={item.disabled}
          star="true"
          validation={!!validationErrors?.key}
          onChange={v => onChange(`${identifier}key`, v)}
        />
        <Checkbox
          checked={item.isShowName}
          onChange={v => onChange(`${identifier}isShowName`, v)}
          size="1rem"
          label={t('projects.checkbox.label')}
        />
        {!!validationErrors?.key && (
          <span className="text-red-500 text-sm block leading-1">{validationErrors.key}</span>
        )}
      </div>

      <div className="mb-4">
        <TextArea
          rows="15"
          classNameInput={item.disabled}
          label={t('projects.description.label')}
          value={item.body}
          disabled={item.disabled}
          placeholder={t('projects.description.placeholder')}
          star="true"
          validation={!!validationErrors?.body}
          onChange={v => onChange(`${identifier}body`, v)}
          maxLength={600}
        />
        {!!validationErrors?.body && (
          <span className="text-red-500 text-sm block leading-1">{validationErrors.body}</span>
        )}
      </div>

      <div className="grid grid-cols-2 col-gap-4 mb-3">
        <DatePicker
          name={`${t('projects.start_date.label')}*`}
          value={item.start}
          onChange={v => onChange(`${identifier}start`, v)}
          clickResetDate={v => onChange(`${identifier}start`, null)}
          validationMessage={validationErrors?.start}
          views={['year', 'month']}
          format={shortMonthYearFormat}
        />
        <DatePicker
          name={t('projects.end_date.label')}
          value={item.end}
          onChange={v => onChange(`${identifier}end`, v)}
          clickResetDate={v => onChange(`${identifier}end`, null)}
          validationMessage={validationErrors?.end}
          views={['year', 'month']}
          format={shortMonthYearFormat}
        />
      </div>

      <div className="mb-6">
        <TextField
          label={t('projects.role.label')}
          placeholder={t('projects.role.placeholder')}
          value={item.role}
          validation={!!validationErrors?.role}
          onChange={v => onChange(`${identifier}role`, v)}
        />
        {!!validationErrors?.role && (
          <span className="text-red-500 text-sm block leading-1">{validationErrors.role}</span>
        )}
      </div>

      <TextField
        className="mb-6"
        classNameInput={item.disabled}
        label={t('projects.app.label')}
        placeholder={t('projects.app.placeholder')}
        value={item.type}
        disabled={item.disabled}
        onChange={v => onChange(`${identifier}type`, v)}
      />

      <div className="mb-4">
        <TextArea
          rows="15"
          label={t('projects.task_executed.label')}
          value={item.tasksExecuted}
          validation={!!validationErrors?.tasksExecuted}
          placeholder={t('projects.task_executed.placeholder')}
          onChange={v => onChange(`${identifier}tasksExecuted`, v)}
          maxLength={238}
        />
        {!!validationErrors?.tasksExecuted && (
          <span className="text-red-500 text-sm block leading-1">
            {validationErrors.tasksExecuted}
          </span>
        )}
      </div>

      <div className="mb-6">
        <TextArea
          rows="15"
          label={t('projects.toolsAndTechnologies.label')}
          value={item.task_body}
          validation={!!validationErrors?.task_body}
          placeholder={t('projects.toolsAndTechnologies.label')}
          onChange={v => onChange(`${identifier}task_body`, v)}
          maxLength={240}
        />
        {!!validationErrors?.task_body && (
          <span className="text-red-500 text-sm block leading-1">{validationErrors.task_body}</span>
        )}
      </div>
    </div>
  );
};

export default Form;
