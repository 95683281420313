import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import set from 'lodash/set';
import { get } from 'lodash';
import { addItem } from '../../utils';
import ItemHeading from '../ItemHeading';
import AddItemButton from '../AddItemButton';
import Form from './Form';

const AddItem = ({ heading, dispatch, type, idTab }) => {
  const [isOpen, setOpen] = useState(false);
  const [errors, setErrors] = useState({
    key: false,
    years: false,
  });
  const createCleanItem = () => ({
    id: uuidv4(),
    enable: true,
    key: '',
    years: '',
    notActive: false,
    level: '',
    disabled: false,
    disabledAC: false,
    isDefault: false,
    wasChanged: false,
    tabId: idTab,
    isSkillList: false
  });

  const [item, setItem] = useState(createCleanItem());

  const onChange = (key, value) => {
    if (key === 'key') {
      setItem(items => set({ ...items }, key, value));
      if (value !== '') {
        setItem(items => set({ ...items }, 'disabledAC', true));
      } else {
        setItem(items => set({ ...items }, 'disabledAC', false));
      }
    } else {
      setItem(items => set({ ...items }, key, value));
    }
  };

  const onTechSkillSelect = (itemTest) => {
    if (itemTest) {
      setItem(items => set({ ...items }, 'key', get(itemTest, 'skill_title')));
      setItem(items => set({ ...items }, 'disabled', true));
      setItem(items => set({ ...items }, 'isSkillList', true));
      setErrors({
        key: false,
        years: errors.years,
      });
    } else {
      setItem(items => set({ ...items }, 'key', ''));
      setItem(items => set({ ...items }, 'disabled', false));
      setItem(items => set({ ...items }, 'isSkillList', false));
    }
  };

  const onSubmit = () => {
    if (item.key === '' || item.years === '') {
      setErrors({
        key: item.key === '',
        years: item.years === '',
      });
      return;
    }

    item.isDefault = true;
    item.wasChanged = true;

    item.key = item.key.charAt(0).toUpperCase() + item.key.slice(1);

    addItem(dispatch, type, item);

    setItem(createCleanItem());

    setOpen(false);
  };

  return (
    <div className="my-4 border border-gray-200 rounded">
      <ItemHeading heading={heading} setOpen={setOpen} isOpen={isOpen} />

      <div className={`${isOpen ? 'block' : 'hidden'} px-5 pb-5`}>
        <Form item={item} onChange={onChange} onTechSkillSelect={onTechSkillSelect} idTab={idTab} type={type} errors={errors} setErrors={setErrors}/>

        <AddItemButton onSubmit={onSubmit} />
      </div>
    </div>
  );
};

export default AddItem;
