import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import set from 'lodash/set';
import { addItem } from '../../utils';
import Form from './Form';

const AddItem = ({ heading, dispatch }) => {
  const [errors, setErrors] = useState({
    value: false,
  });
  const [item, setItem] = useState({
    id: uuidv4(),
    enable: true,
    value: '',
  });

  const onChange = (key, value) => setItem(items => set({ ...items }, key, value));

  const add = () => {
    if (item.value === '') {
      setErrors({
        value: item.value === '',
      });
      return;
    }

    addItem(dispatch, 'skills', item);

    setItem({
      id: uuidv4(),
      enable: true,
      value: '',
    });
  };

  return (
    <div className="grid grid-cols-4 gap-4 mb-5">
      <div className="col-span-3">
        <Form item={item} onChange={onChange} errors={errors} setErrors={setErrors}/>
      </div>

      <button type="button" onClick={add}
              className="col-span-1 bg-gray-600 hover:bg-gray-700 cursor-pointer text-sm font-medium rounded"
      >
        <div className="flex justify-center items-center">
          <i className="material-icons font-bold text-white text-lg">add</i>
        </div>
      </button>
    </div>
  );
};

export default AddItem;
