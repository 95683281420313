import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { get, } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { getCVByUsername } from '../../../services/GetCVByUsername';
import { importData, setSelectedUserId, setSelectedCvId } from '../../../utils';

import AppContext from '../../../context/AppContext';
import { getMarkIcon } from '../../../helpers/AppHelpers';
import { infoToast } from '../../../shared/toast';

import spinner from '../../../assets/images/spinner.gif';
import SearchBar from './search/SearchBar';

const UsersTab = ({ dispatch, lastCvUpdate }) => {
  const context = useContext(AppContext);
  const { state } = context;
  const { ownUserInfo } = state;

  const { t } = useTranslation('rightSidebar');
  const history = useHistory();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const username = query.get('username');

  const [filteredCvData, setFilteredCvData] = useState([]);
  const [loadingSpinner, setLoadingSpinner] = useState(true);

  useEffect(() => {
    if (username) {
      (async () => {
        const res = await getCVByUsername(username);
        if (res.length > 0) {
          const importedObject = { data: { ...res[0] } };
          const cvId = get(importedObject.data, 'id');
          const userId = get(importedObject.data, 'user._id');
          setSelectedCvId(dispatch, cvId);
          setSelectedUserId(dispatch, userId);
          importData(dispatch, importedObject);
        } else {
          infoToast('CV is not found');
        }
      })();
    }
  }, [username, dispatch]);

  const clickByUser = (cv) => {
    const newUsername = get(cv, 'user.username');
    history.push(`/user?username=${newUsername}`);
  };

  const clickByOwnCv = () => {
    getCVByUsername(ownUserInfo.username).then((response) => {
      if (response.length > 0) {
        const importedObject = { data: { ...response[0] } };
        importData(dispatch, importedObject);
        setSelectedCvId(dispatch, '');
        setSelectedUserId(dispatch, null);
        history.push('/');
      } else {
        infoToast('CV is not found');
      }
    });
  };

  const showCVList = () => {
    return (
      <>
      {filteredCvData.length ? (
        filteredCvData.map((cv) => (
          <div
            key={get(cv, 'id')}
            onClick={() => clickByUser(cv)}
            className="cursor-pointer flex justify-between items-center hover:bg-gray-200 p-1"
          >
            <div className="select-none">
              <div>
                {get(cv, 'summary.lastName')} {get(cv, 'summary.firstName')}
              </div>
              <div className="text-gray-500 text-xs">{get(cv, 'user.username')}</div>
            </div>
            <div className="">
              <img src={getMarkIcon(cv)} className="w-4 h-4" alt="" />
            </div>
          </div>
        ))
      ) : (
        <span>Nothing to show!</span>
      )}
      </>
    )
  };

  return (
    <>
      <div className="mb23 flex justify-between items-center">
        <div className="title_tab">{t('users.title.label')}</div>
        <div
          onClick={clickByOwnCv}
          className="rounded cursor-pointer select-none title_tab btn_my_cv"
        >
          <div>My CV</div>
        </div>
      </div>

      <hr className="my-6" />

      <SearchBar 
        setLoadingSpinner={setLoadingSpinner}
        setFilteredCvData={setFilteredCvData}
        lastCvUpdate={lastCvUpdate}
      />

      <hr className="my-6" />
      
      <div className="flex justify-center">
        {loadingSpinner && <img src={spinner} className="spinner flex" alt="spinner"/>} 
      </div>

      {!loadingSpinner && showCVList()}
    </>
  );
};

export default UsersTab;
