import config from '../config';
import { getElementFromStorage } from '../utils/storage';

export const PutData = (userData, cvId) => {
  const BaseURL = `${config.http.BASE_URL}/resume-items/${cvId}`;
  const token = getElementFromStorage('jwtToken');
  return fetch(BaseURL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token === 'undefined' ? '' : token}`,
    },
    body: JSON.stringify(userData),
  }).then(
    (response) =>
      response
        .json()
        .then((json) => json)
        .catch(() => ({
          error: true,
        })),
    (error) => {
      throw new Error(error.message);
    },
  );
};

export default PutData;
