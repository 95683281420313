import React, { forwardRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import styles from './ReportPopup.module.css';
import TextArea from '../TextArea';
import putCVReport from '../../services/PutCVReport';
import { errorToast, successToast } from '../toast';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ReportPopup({ open, onClose, senderID, data }) {
  const [message, setMessage] = useState('');

  const onAgree = () => {
    putCVReport({_id: data.id, version: data.version, user:{username: data.user.username}}, message, senderID).then((res) => {
      if (res.error) {
        errorToast();
      } else {
        successToast('CV reported');
      }
    });
    setMessage('');
    onClose();
  }

  const onCancel = () => {
    setMessage('');
    onClose();
  }

  return (
    <Dialog
      open={open}
      classes={{
        root: styles.popupRoot,
        paper: styles.popupContainer,
      }}
      BackdropProps={{
        classes: {
          root: styles.backDrop,
        },
      }}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle
        classes={{
          root: styles.popupTitle,
        }}
        id="alert-dialog-slide-title">
        If you have any feedback, comments or suggestions how to improve the CV, please leave them below
      </DialogTitle>
      <DialogContent
        classes={{
          root: styles.popupText,
        }}>
        <TextArea
          rows="15"
          className="mb-1"
          label="Feedback message"
          value={message}
          placeholder="Feedback message"
          onChange={v => setMessage(v)}
        />
      </DialogContent>
      <DialogActions
        classes={{
          root: styles.popupControls,
        }}>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={onAgree} disabled={!message.length}>
          Submit
        </Button>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
