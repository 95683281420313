import React from 'react';

export default function SearchItem({item, onDelete}) {
  return (
    <div className="mb-1">
      <label className="text-sm text-gray-600 ml-2 select-none">{item.label}</label>
      <div className="grid grid-cols-10">
        <div className="col-span-9">
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            value={item.title}
            type="text"
            disabled
          />
        </div>

        <button
          type="button"
          onClick={() => onDelete(item)}
          className="col-span-1 text-gray-600 hover:text-red-600 text-sm font-medium"
        >
          <div className="flex justify-center items-center">
            <i className="material-icons font-bold text-lg">close</i>
          </div>
        </button>
      </div>
  </div>)
}
