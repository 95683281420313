import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const AutoComplete = ({ label, onChange, open, onOpen, onClose, onInputChange, options, placeholder, disabled, error, ...rest  }) => (
  <div className="mb-2">
    {label && (
      <label className={`uppercase tracking-wide ${disabled ? 'text-gray-400' : 'text-gray-600'} text-xs font-semibold mb-2`}>
        {label}
      </label>
    )}
    <div 
      className={`relative w-full bg-gray-200 text-gray-800 rounded px-4 leading-tight focus:outline-none
      border ${error ? 'border-red-400' : 'border-gray-200'}`}
    >
      <Autocomplete
        id="asynchronous-demo"
        options={options}
        disabled={disabled}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        onChange={(event, valueZ) => onChange(valueZ)}
        renderInput={(params) => <TextField
          {...params}
          label={placeholder}
          margin="normal"
        />}
        {...rest}
      />
    </div>
  </div>
);

AutoComplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string,
}

AutoComplete.defaultProps = {
  label: null,
}

export default AutoComplete;
