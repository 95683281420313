import config from '../config';
 
export const GetTechSkills = (id) => {
  const urlTemplate = `${config.http.BASE_URL}/Autocomplete-tech-skills?tab=${id}&_limit=300`;
  return fetch(urlTemplate, { method: 'GET' })
    .then(response => (response.status !== 200 ? ({
      error: true,
    }) : response.json().then(json => json)
      .catch(() => ({
        error: true,
      }))), (error) => {
      throw new Error(error.message);
    });
};

export default GetTechSkills;
