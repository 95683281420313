import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import Dropdown from '../../../../shared/Dropdown';
import { GetAllCV } from '../../../../services/GetAllCV';
import { getSearchResults } from '../../../../services/GetSearchResults';
import { langLevels } from '../../../../helpers/AppHelpers';
import { GetPositions } from '../../../../services/GetPositions';
import { getKeySkills } from '../../../../services/GetKeySkills';
import AutoComplete from '../../../../shared/AutoComplete';
import Items from './Items';
import AddItemButton from '../../../../shared/AddItemButton';

const searchParamList = [
  { key: 0, value: 'select', placeholder: 'Select parameter', disabled: true },
  { key: 1, value: 'user', placeholder: 'User' },
  { key: 2, value: 'position', placeholder: 'Position' },
  { key: 3, value: 'techSkills', placeholder: 'Technical Skill' },
  { key: 4, value: 'projects', placeholder: 'Project Name' },
  { key: 5, value: 'keySkills', placeholder: 'Key Skill' },
  { key: 6, value: 'english', placeholder: 'English level' },
];
const singleSearchParams = ['user', 'position', 'projects', 'english'];

export default function SearchBar({ setLoadingSpinner, setFilteredCvData, lastCvUpdate }) {
  const [searchParam, setSearchParam] = useState('select');
  const [positions, setPositions] = useState([]);
  const [keySkills, setKeySkills] = useState([]);
  const [open, setOpen] = useState(false);

  const [item, setItem] = useState('');
  const [searchItems, setSearchItems] = useState([]);
  const [paramOptions, setParamOptions] = useState(searchParamList);
  const [error, setError] = useState(false);

  const { t } = useTranslation('rightSidebar');

  const inputParams = ['user', 'techSkills', 'projects'];

  const clear = () => setItem('');

  const filterByParameter = (data) => {
    // data = filter(data, (cv) => {
    //   return cv.user && cv.user.email.includes(companyName);
    // });
    return data.sort((a, b) => get(a, 'user.username').localeCompare(get(b, 'user.username')));
  };

  const getAllCvData = useCallback(() => {
    setLoadingSpinner(true);
    (async () => {
      let cvsRes = await GetAllCV();
      cvsRes = filterByParameter(cvsRes.length ? cvsRes : []);
      setFilteredCvData(cvsRes.length ? cvsRes : []);
      setLoadingSpinner(false);
    })();
  }, [setFilteredCvData, setLoadingSpinner]);

  useEffect(() => {
    getAllCvData();
  }, [lastCvUpdate, getAllCvData]);

  useEffect(() => {
    (async () => {
      let positionsFromRes = await GetPositions();
      positionsFromRes = positionsFromRes.sort((a, b) =>
        get(a, 'title').localeCompare(get(b, 'title')),
      );
      const testArr = {
        id: 'Select position',
        title: '',
        disabled: true,
      };
      positionsFromRes.unshift(testArr);
      setPositions(positionsFromRes);
      setLoadingSpinner(false);
    })();
  }, [setLoadingSpinner]);

  useEffect(() => {
    const result = searchParamList.filter((value) => {
      const canBeOnlyOne = singleSearchParams.findIndex((single) => single === value.value) !== -1;
      if (!canBeOnlyOne) return true;
      const notInItems = searchItems.findIndex((arrValue) => arrValue.type === value.value) === -1;
      return notInItems;
    });
    setParamOptions(result);
  }, [searchItems]);

  useEffect(() => {
    (async () => {
      const res = await getKeySkills();
      setKeySkills(res);
    })();
  }, []);

  const handleSearch = (value, searchItemsArray = searchItems) => {
    setLoadingSpinner(true);
    (async () => {
      const searchItem = inputParams.includes(searchParam) ? item : value;
      const searchArr = JSON.parse(JSON.stringify(searchItemsArray));
      if (searchItem) searchArr.push({ type: searchParam, title: searchItem });
      const sortedArr = searchArr.sort((a, b) => a.type.localeCompare(b.type));
      const str = sortedArr.reduce((prev, cur, index, start) => {
        let result = '';
        if (start[index - 1]?.type === cur.type) {
          result = `${prev},${cur.title}`;
        } else {
          result = `${prev}&${cur.type}_contains=${cur.title}`;
        }
        return result;
      }, '');
      const resultSTR = str.substring(1, str.length);
      const response = await getSearchResults(resultSTR);
      const cvsRes = filterByParameter(response.length ? response : []);
      setFilteredCvData(cvsRes.length ? cvsRes : []);
      setLoadingSpinner(false);
    })();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') handleSearch();
  };

  const onChangePosition = (value) => {
    setItem(value);
    setError(false);
    handleSearch(value);
  };

  const onChangeEngLvl = (value) => {
    setItem(value);
    setError(false);
    handleSearch(value);
  };

  const onChangeAutoValue = (value) => {
    const reqString = value ? value.title : '';
    setError(false);
    handleSearch(reqString);
    setItem(value);
  };

  const generateItem = () => {
    const addItem = {};
    switch (searchParam) {
      case 'keySkills':
        addItem.title = item ? item.title.trim() : '';
        break;
      default:
        addItem.title = item.trim();
    }
    addItem.type = searchParam;
    addItem.label = searchParamList.find((element) => element.value === searchParam).placeholder;
    return addItem;
  };

  const onAdd = () => {
    const searchBarItem = generateItem();
    if (searchBarItem.title) {
      const newArr = JSON.parse(JSON.stringify(searchItems));
      newArr.push(searchBarItem);
      setSearchParam('select');
      setSearchItems(newArr);
      handleSearch(searchBarItem.title);
      clear();
    } else {
      setError(true);
    }
  };

  const onDelete = (searchItemsItem) => {
    const deleteIndex = searchItems.findIndex(
      (value) => JSON.stringify(value) === JSON.stringify(searchItemsItem),
    );
    const newArr = JSON.parse(JSON.stringify(searchItems));
    newArr.splice(deleteIndex, 1);
    setSearchItems(newArr);
    setSearchParam('select');
    let value = item;
    if (searchParam === 'keySkills') value = value ? value.title : '';
    handleSearch(value, newArr);
  };

  return (
    <div>
      <Items itemsArr={searchItems} onDelete={onDelete} />

      <Dropdown
        value={searchParam}
        options={paramOptions}
        onChange={(v) => {
          setSearchParam(v);
          setError(false);
          clear();
        }}
        optionItem={(x) => (
          <option
            key={`search${x.key}`}
            value={x.value}
            // selected={x.disabled}
            disabled={x.disabled}
          >
            {x.placeholder}
          </option>
        )}
      />

      {inputParams.includes(searchParam) && (
        <input
          className={`mb-2 appearance-none block w-full bg-gray-200 text-gray-700 border 
            ${error ? 'border-red-400' : 'border-gray-200'}
            rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
          placeholder={t('users.input.placeholder')}
          value={item}
          onChange={(e) => {
            setItem(e.target.value);
            setError(false);
          }}
          type="text"
          onKeyPress={handleKeyPress}
        />
      )}
      {searchParam === 'position' && (
        <Dropdown
          value={item}
          options={positions}
          onChange={(v) => onChangePosition(v)}
          optionItem={(x) => (
            <option key={x.id} value={x.title} disabled={x.disabled}>
              {x.disabled ? 'Select Position' : x.title}
            </option>
          )}
          error={error}
        />
      )}
      {searchParam === 'english' && (
        <Dropdown
          value={item}
          options={langLevels}
          onChange={(v) => onChangeEngLvl(v)}
          optionItem={(x) => (
            <option key={x.key} value={x.value} selected={x.disabled} disabled={x.disabled}>
              {x.disabled ? 'Select Minimum Level' : x.value}
            </option>
          )}
          error={error}
        />
      )}
      {searchParam === 'keySkills' && (
        <AutoComplete
          value={item}
          open={open}
          onOpen={() => setOpen(!open)}
          onClose={() => setOpen(!open)}
          options={keySkills}
          onChange={(v) => onChangeAutoValue(v)}
          placeholder=""
          getOptionLabel={(option) => get(option, 'title') || ''}
          error={error}
        />
      )}

      {searchParam !== 'select' && <AddItemButton onSubmit={() => onAdd()} />}
    </div>
  );
}
