import React, { forwardRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import styles from './ExpiredPopup.module.css';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ExpiredPopup({open}) {
  const [wasShown, setWasShown] = useState(false)
  const onClick = () => {
    setWasShown(true);
  }
  
  return (
    <Dialog
      open={open && !wasShown}
      classes={{
        root: styles.popupRoot,
        paper: styles.popupContainer,
      }}
      BackdropProps={{
        classes: {
          root: styles.backDrop,
        },
      }}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClick}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle
        classes={{
          root: styles.popupTitle,
        }}
        id="alert-dialog-slide-title">
        Attention!
      </DialogTitle>
      <DialogContent
        classes={{
          root: styles.popupText,
        }}>
        <p>The last CV update was more than 6 months ago.</p> 
        <p>Please update your CV with actual information</p>
      </DialogContent>
      <DialogActions
        classes={{
          root: styles.popupControls,
        }}>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={onClick}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
