import Gengar, { Image as GengarPreview } from './gengar';

export default [
  {
    key: 'gengar',
    name: 'Gengar',
    component: Gengar,
    preview: GengarPreview,
  },
];
