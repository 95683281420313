import React, { Suspense, useContext, useState } from 'react';

import GoogleLogin from 'react-google-login';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import { first, get } from 'lodash';
import { useTranslation } from 'react-i18next';

import buildingsImg from '../../assets/images/buildings.jpg';
import logoImg from '../../assets/images/softteco_logo.svg';

import { getElementFromStorage, saveElementToStorage } from '../../utils/storage';
import {
  importData,
  isAdmin,
  isSale,
  setSelectedUserId,
  setOwnCvId,
  setOwnUserInfo,
} from '../../utils';

import { PostDataLogin } from '../../services/PostDataLogin';
import { getCVByTempLink } from '../../services/GetCVByTempLink';
import { changeLoggedIn } from '../../actions';
import AppContext from '../../context/AppContext';
import { errorToast, infoToast } from '../../shared/toast';
import { config } from '../../config';

const Login = props => {
  const [isFirstCall, setIsFirstCall] = useState(true);
  const context = useContext(AppContext);
  const { dispatch } = context;

  const { t } = useTranslation('login');
  const history = useHistory();
  const { loggedIn } = props;
  let queryUserName;
  let queryTempLinkId;

  // eslint-disable-next-line react/destructuring-assignment
  if (props.location.state) {
    const query = props.location?.state?.from?.search;
    const findId = query.split('?username=');
    queryUserName = query.split('?username=')[findId.length - 1];
    const linkId = query.split('&id=');
    queryTempLinkId = linkId[linkId.length - 1];
  }

  const AccessToken = getElementFromStorage('userAccessToken');

  if (loggedIn) {
    const { from } = props.location.state || { from: { pathname: '/' } };
    return <Redirect to={from} />;
  }

  const checkRole = res => {
    const role = get(res.user.role, 'id');
    const ownUserName = get(res.user, 'username');
    if (role === config.manager.id || role === config.teamManager.id) {
      isAdmin(dispatch, true);
    } else if (role === config.saleRole.id) {
      isSale(dispatch, true);
    } else {
      history.push('/');
      if (!!queryUserName && ownUserName !== queryUserName) {
        infoToast(
          'Sorry, but you do not have the permissions to review the selected template. Please contact the administrator.',
        );
      }
    }
  };

  const showError = isToken => {
    if (!isToken) {
      errorToast();
    } else {
      infoToast('Session expired. Please re-login to renew your session.');
    }
  };

  const getUserData = (accessToken, isToken) => {
    saveElementToStorage('userAccessToken', accessToken);
    PostDataLogin(accessToken).then(res => {
      if (res.error) {
        showError(isToken);
      } else if (get(res.user, 'resume_items').length === 0) {
        errorToast();
      } else {
        saveElementToStorage('jwtToken', get(res, 'jwt'));
        const firstCvItem = first(get(res.user, 'resume_items'));
        const buf = firstCvItem.user;
        firstCvItem.user = { _id: buf, team_manager: get(res, 'user.team_manager._id') };
        if (!queryUserName) {
          importData(dispatch, { data: { ...firstCvItem } });
          setSelectedUserId(dispatch, null);
        }
        const userInfo = {
          userId: res?.user?.id || '',
          username: res?.user?.username || '',
          email: res?.user?.email || '',
        };
        setOwnUserInfo(dispatch, userInfo);
        setOwnCvId(dispatch, firstCvItem.id);
        checkRole(res);
        // if temporary link
        if (queryTempLinkId && !queryTempLinkId.match('username')) {
          isAdmin(dispatch, false);
          isSale(dispatch, false);
          getCVByTempLink(queryTempLinkId).then(response => {
            if (response.error) {
              if (response.responseStatus === 404 || response.responseStatus === 500)
                errorToast('The link is expired or doesn’t exist. Please, request a new one');
            } else {
              const tempLinkCV = first(get(response.user, 'resume_items'));
              importData(dispatch, { data: { ...tempLinkCV } });
            }
          });
        }
        props.changeLoggedIn(true);
      }
    });
  };

  if (AccessToken && isFirstCall) {
    getUserData(AccessToken, true);
    setIsFirstCall(false);
  }

  const responseGoogle = response => {
    const accessToken = response.tokenObj.access_token;
    getUserData(accessToken);
  };

  const failureResGoogle = response => {
    const errorFromResponse = get(response, 'error');
    if (errorFromResponse !== 'popup_closed_by_user') {
      errorToast();
    }
  };

  return (
    <Suspense fallback="Loading...">
      <div className="app_host">
        <img src={buildingsImg} alt="buildings" className="app_img" />
        <div className="app_block flex justify-center items-center flex-col">
          <div className="text-center font-bold mb-4 text-4xl color-blue">{t('cv_builder')}</div>
          <div>
            <p className="text-lg color-blue">{t('use_google_acc')}</p>
          </div>
          <div className="pt-8">
            <GoogleLogin
              clientId={config.auth.GOOGLE_CLIENT_ID}
              onSuccess={responseGoogle}
              onFailure={failureResGoogle}
              cookiePolicy="single_host_origin"
              className="h-16"
            >
              {t('sign_in_with_google')}
            </GoogleLogin>
          </div>
          <img src={logoImg} className="app_logo" alt="softteco" />
        </div>
      </div>
    </Suspense>
  );
};
export default connect(
  state => ({
    loggedIn: state.loggedIn,
  }),
  {
    changeLoggedIn,
  },
)(Login);
