import config from '../config';  

export const GetTemplates = () => {
  const BaseURL = `${config.http.BASE_URL}/templates`;
  return fetch(BaseURL, { method: 'GET' })
    .then(response => (response.status !== 200 ? ({
      error: true,
    }) : response.json().then(json => json)
      .catch(() => ({
        error: true,
      }))), (error) => {
      throw new Error(error.message);
    });
};

export default GetTemplates;
