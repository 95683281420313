import React, { useState } from 'react';
import ItemHeadingWithDeleteButton from '../ItemHeadingWithDeleteButton';
import Form from './Form';
import ItemActions from '../ItemActions';

const Item = ({ item, index, onChange, dispatch, first, last, type, idTab }) => {
  const [isOpen, setOpen] = useState(false);
  const [errors, setErrors] = useState({
    key: false,
    major: false,
  });
  const identifier = `data.${type}.items[${index}].`;

  return (
    <div className="my-4 border border-gray-200 rounded">
      <ItemHeadingWithDeleteButton setOpen={setOpen} isOpen={isOpen} item={item} dispatch={dispatch} type={type}/>

      <div className={`${isOpen ? 'block' : 'hidden'} px-5 pb-5`}>
        <Form item={item} onChange={onChange} identifier={identifier} idTab={idTab} type={type} errors={errors} setErrors={setErrors}/>

        <ItemActions
          dispatch={dispatch}
          first={first}
          identifier={identifier}
          item={item}
          last={last}
          onChange={onChange}
          type={type}
        />
      </div>
    </div>
  );
};

export default Item;
