/* eslint-disable new-cap */
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';

const moveBy = (array, element, delta) => {
  const index = array.findIndex(item => item.id === element.id);
  const newIndex = index + delta;
  if (newIndex < 0 || newIndex === array.length) return;

  const buf = array[index];
  array.splice(index, 1);
  array.splice(newIndex, 0, buf);
};

const hexToRgb = hex => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null;
};

const copyToClipboard = text => {
  const textArea = document.createElement('textarea');
  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = 0;
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  const successful = document.execCommand('copy');
  document.body.removeChild(textArea);
  return successful;
};

const addItem = (dispatch, key, value) => {
  dispatch({
    type: 'add_item',
    payload: {
      key,
      value,
    },
  });
};

const unshiftItem = (dispatch, key, value) => {
  dispatch({
    type: 'add_item_unshift',
    payload: {
      key,
      value,
    },
  });
};

const deleteItem = (dispatch, key, value) => {
  dispatch({
    type: 'delete_item',
    payload: {
      key,
      value,
    },
  });
};

const moveItemUp = (dispatch, key, value) => {
  dispatch({
    type: 'move_item_up',
    payload: {
      key,
      value,
    },
  });
};

const moveItemDown = (dispatch, key, value) => {
  dispatch({
    type: 'move_item_down',
    payload: {
      key,
      value,
    },
  });
};

const moveItemBy = (dispatch, key, value, moveBy) => {
  dispatch({
    type: 'move_item_by',
    payload: {
      key,
      value,
      moveBy,
    },
  });
};


const importData = (dispatch, payload) => {
  dispatch({
    type: 'import_data',
    payload,
  });
};

const isAdmin = (dispatch, payload) => {
  dispatch({
    type: 'isAdmin',
    payload,
  });
};

const isSale = (dispatch, payload) => {
  dispatch({
    type: 'isSale',
    payload,
  });
};

const setOwnCvId = (dispatch, payload) => {
  dispatch({
    type: 'setOwnCvId',
    payload,
  });
};

const setOwnUserInfo = (dispatch, payload) => {
  dispatch({
    type: 'setOwnUserInfo',
    payload,
  });
};

const setSelectedCvId = (dispatch, payload) => {
  dispatch({
    type: 'setSelectedCvId',
    payload,
  });
};

const setSelectedUserId = (dispatch, payload) => {
  dispatch({
    type: 'setSelectedUserId',
    payload,
  });
};

const changeSpinnerValue = (dispatch, payload) => {
  dispatch({
    type: 'change_spinner_value',
    payload,
  });
};

const importJson = (event, dispatch) => {
  const fr = new FileReader();
  fr.addEventListener('load', () => {
    const importedObject = JSON.parse(fr.result);
    dispatch({ type: 'import_data', payload: importedObject });
  });
  fr.readAsText(event.target.files[0]);
};

const saveAsPdf = (pageRef, panZoomRef, quality, type, firstName, lastName, position) =>
  new Promise(resolve => {
    panZoomRef.current.autoCenter(1);
    panZoomRef.current.reset();

    setTimeout(() => {
      html2canvas(pageRef.current, {
        scale: 5,
        useCORS: true,
        allowTaint: true,
      }).then(canvas => {
        const image = canvas.toDataURL('image/jpeg', quality / 100);
        const doc = new jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: type === 'unconstrained' ? [canvas.width, canvas.height] : 'a4',
        });

        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const widthRatio = pageWidth / canvas.width;
        const heightRatio = pageHeight / canvas.height;
        const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

        const canvasWidth = canvas.width * ratio;
        const canvasHeight = canvas.height * ratio;

        let marginX = 0;
        let marginY = 0;

        if (type !== 'unconstrained') {
          marginX = (pageWidth - canvasWidth) / 2;
          marginY = (pageHeight - canvasHeight) / 2;
        }

        doc.addImage(image, 'JPEG', marginX, marginY, canvasWidth, canvasHeight, null, 'SLOW');
        doc.save(`CV${firstName ? ` - ${firstName}` : ''}${lastName ? ` ${lastName}` : ''}${position ? ` (${position})` : ''}.pdf`);
        resolve();
      });
    }, 250);
  });

const saveAsMultiPagePdf = (pageRef, panZoomRef, quality, firstName, lastName, position) =>
  new Promise(resolve => {
    panZoomRef.current.autoCenter(1);
    panZoomRef.current.reset();

    setTimeout(() => {
      html2canvas(pageRef.current, {
        scale: 5,
        useCORS: true,
        allowTaint: true,
      }).then(canvas => {
        const image = canvas.toDataURL('image/jpeg', quality / 100);
        const doc = new jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: 'a4',
        });

        const pageHeight = doc.internal.pageSize.getHeight();
        const canvasWidth = doc.internal.pageSize.getWidth();
        const canvasHeight = (canvas.height * canvasWidth) / canvas.width;
        let marginTop = 0;
        let heightLeft = canvasHeight;

        doc.addImage(image, 'JPEG', 0, marginTop, canvasWidth, canvasHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          marginTop = heightLeft - canvasHeight;
          doc.addPage();
          doc.addImage(image, 'JPEG', 0, marginTop, canvasWidth, canvasHeight);
          heightLeft -= pageHeight;
        }

        doc.save(`CV${firstName ? ` - ${firstName}` : ''}${lastName ? ` ${lastName}` : ''}${position ? ` (${position})` : ''}.pdf`);
        resolve();
      });
    }, 250);
  });

export {
  hexToRgb,
  copyToClipboard,
  addItem,
  unshiftItem,
  deleteItem,
  moveItemUp,
  moveItemDown,
  importData,
  isAdmin,
  isSale,
  changeSpinnerValue,
  importJson,
  saveAsPdf,
  saveAsMultiPagePdf,
  setOwnCvId,
  setOwnUserInfo,
  setSelectedCvId,
  setSelectedUserId,
  moveItemBy,
  moveBy,
};
