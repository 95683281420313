import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AppContext from '../../../context/AppContext';
import Checkbox from '../../../shared/Checkbox';
import Item from '../../../shared/Responsibilities/Item';
import AddItem from '../../../shared/Responsibilities/AddItem';

const ResponsibilitiesTab = ({ data, onChange }) => {
  const context = useContext(AppContext);
  const { dispatch } = context;
  const { t } = useTranslation('leftSidebar');

  useEffect(() => {
    if (!('experience' in data)) {
      dispatch({
        type: 'migrate_section',
        payload: {
          key: 'experience',
          value: {
            enable: false,
            heading: 'Experience',
            items: [],
          },
        },
      });
    }
  }, [data, dispatch]);

  return (
    'experience' in data && (
      <>
        <div className="mb23 flex justify-between items-center">
          <div className="title_tab">{t('experience.title.label')}</div>

          <div className="col-span-1">
            <Checkbox
              checked={data.experience.enable}
              onChange={v => onChange('data.experience.enable', v)}
            />
          </div>
        </div>

        <hr className="my-6" />

        {data.experience.items.map((x, index) => (
          <Item
            item={x}
            key={x.id}
            index={index}
            onChange={onChange}
            dispatch={dispatch}
            first={index === 0}
            last={index === data.experience.items.length - 1}
          />
        ))}

        <AddItem heading={data.experience.heading} dispatch={dispatch} />
      </>
    )
  );
};

export default ResponsibilitiesTab;
