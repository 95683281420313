import React, { forwardRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import styles from './FeedBackPopup.module.css';
import TextArea from '../TextArea';
import TextField from '../TextField';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function FeedBackPopup({ data, onAgree, onDismiss, values, setValues }) {
  const onChange = (key, value) => {
    value = value.trimStart();
    setValues({
      ...values,
      [key]: value,
    });
  };

  return (
    <Dialog
      open={data.isOpened}
      classes={{
        root: styles.popupRoot,
        paper: styles.popupContainer,
      }}
      BackdropProps={{
        classes: {
          root: styles.backDrop,
        },
      }}
      TransitionComponent={Transition}
      keepMounted
      onClose={onDismiss}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle
        classes={{
          root: styles.popupTitle,
        }}
        id="alert-dialog-slide-title">
        {data.title}
      </DialogTitle>
      <DialogContent
        classes={{
          root: styles.popupText,
        }}>
        <TextField
          className="mb-6"
          label="Subject"
          placeholder="Subject"
          value={values?.subject || ''}
          onChange={v => onChange('subject', v)}
        />
        <TextArea
          rows="15"
          className="mb-1"
          label="Feedback message"
          value={values?.message || ''}
          placeholder="Feedback message"
          onChange={v => onChange('message', v)}
        />
      </DialogContent>
      <DialogActions
        classes={{
          root: styles.popupControls,
        }}>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={onAgree} disabled={!values?.message?.length}>
          Submit
        </Button>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={onDismiss}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
