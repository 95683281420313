import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AppContext from '../../../context/AppContext';
import Checkbox from '../../../shared/Checkbox';
import Item from '../../../shared/Languages/Item';
import AddItem from '../../../shared/Languages/AddItem';
import DragNDropList from '../../../shared/DragNDropList';

const LanguagesTab = ({ data, onChange }) => {
  const context = useContext(AppContext);
  const { dispatch } = context;
  const { t } = useTranslation('leftSidebar');

  useEffect(() => {
    if (!('languages' in data)) {
      dispatch({
        type: 'migrate_section',
        payload: {
          key: 'languages',
          value: {
            enable: false,
            heading: 'Languages',
            items: [],
          },
        },
      });
    }
  }, [data, dispatch]);

  return (
    'languages' in data && (
      <>
        <div className="mb23 flex justify-between items-center">
          <div className="title_tab">{t('languages.title.label')}</div>

          <div className="col-span-1">
            <Checkbox
              checked={data.languages.enable}
              onChange={v => onChange('data.languages.enable', v)}
            />
          </div>
        </div>

        <hr className="my-6" />

        <DragNDropList
          data={data}
          dispatch={dispatch}
          onChange={onChange}
          type="languages"
        >
          {data.languages.items.map((x, index) => (
            <Item
              item={x}
              key={x.id}
              index={index}
              onChange={onChange}
              dispatch={dispatch}
              first={index === 0}
              last={index === data.languages.items.length - 1}
            />
          ))}
        </DragNDropList>


        <AddItem heading={data.languages.heading} dispatch={dispatch} />
      </>
    )
  );
};

export default LanguagesTab;
