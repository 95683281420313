const saveElementToStorage = (name, element) => {
  localStorage.setItem(name, element);
}

const getElementFromStorage = (name) => {
  const item = localStorage.getItem(name);
  return item;
}

const clearLocalStorage = () => {
  localStorage.clear();
}

export {
  saveElementToStorage,
  getElementFromStorage,
  clearLocalStorage,
}
