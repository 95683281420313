import React from 'react';

const TextField = ({
  label,
  placeholder,
  value,
  onChange,
  className,
  classNameInput,
  disabled = false,
  type = 'text',
  star,
  validation
}) => (
  <div className={`w-full flex flex-col ${className}`}>
    {label && (
      <label className={`uppercase tracking-wide ${classNameInput ? 'text-gray-400' : 'text-gray-600'} text-xs font-semibold mb-2`}>
        {label}{star && <span>*</span>}
      </label>
    )}
    <input
      className={`appearance-none block w-full bg-gray-200 ${classNameInput ? 'text-gray-400' : 'text-gray-800'} border ${ !validation ? 'border-gray-200' : 'border-red-400'} rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
      type={type}
      disabled={disabled}
      value={value}
      onChange={e => onChange(e.target.value)}
      placeholder={placeholder}
    />
  </div>
);

export default TextField;
