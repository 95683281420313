import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import { CHANGE_LOGGED_IN } from './actions';

export const createRootReducer = (history) => {
  return combineReducers({
    router: connectRouter(history),
    loggedIn: loggedInReducer,
  });
}

const loggedInReducer = ( state = null, action) => {
  switch (action.type) {
    case CHANGE_LOGGED_IN: return action.newValue;
    default: return state;
  };
};

export default createRootReducer
