import format from 'date-fns/format';
import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';

import logoOfCompany from '../../assets/images/Softteco_logo_vert.png';

import AppContext from '../../context/AppContext';
import { hexToRgb } from '../../utils';
import { monthYearFormat, isValidDatePickerDate } from '../../helpers/AppHelpers';

const Gengar = () => {
  const context = useContext(AppContext);
  const { state } = context;
  const { data, theme } = state;

  const headingExperience = 'Experience';
  const headingLevel = 'Level';

  const { r, g, b } = hexToRgb(theme.colors.accent) || {};

  const FullName = () => (
    <div>
      <h3 className="text-2xl font-bold leading-tight">
        <span>{data.summary.firstName}</span>{data.summary.firstName && data.summary.lastName && (' ')}
        <span>{data?.summary?.lastName[0]?.toUpperCase() || ''}</span></h3>
      <div className="text-base font-medium mt-2 mb-4">
        <span>{data.summary.position_level !== 'Junior' && data.summary.position_level !== 'Middle'
          ? data.summary.position_level + (' ') : ''}</span>
        <span>{data.summary.position}</span></div>
    </div>
  );

  const Heading = ({ title }) => (
    <div
      className="mb-3 mt-3 font-bold tracking-wide text-sm"
      style={{ color: theme.colors.accent }}
    >
      {title}
    </div>
  );

  const EducationItem = x => (
    <div key={x.id} className="mb-3">
      <h6 className="font-semibold">{x.key}</h6>
      <p className="text-xs">{x.major}</p>
      <ReactMarkdown className="mt-2 text-sm" source={x.description.replace(/\n/gi, '\n\n')}/>
    </div>
  );

  const Education = () =>
    data.education &&
    data.education.enable && (
      <div className="mb-6">
        <Heading title={data.education.heading}/>
        {data.education.items.filter(x => x.enable).map(EducationItem)}
      </div>
    );

  const TechSkillsItem = x => (
    <div key={x.id} className="grid grid-cols-3 items-center">
      <h6 className="text-sm font-medium">{x.key}</h6>
      {x.years ? <div className="text-sm text-right mr-2">{x.years} {+x.years <= 1 ? <span>year</span> :
        <span>years</span>}</div> : <div className="text-sm text-right mr-2">Please fill</div>}
      <div className="text-sm text-right mr-2">{x.level === '' ? 'Please fill' : x.level}</div>
    </div>
  );

  const ProgrammingLanguages = () =>
    data.programmingLanguages && data.programmingLanguages.enable && (
      <div>
        <div className="grid grid-cols-3">
          <Heading title={data.programmingLanguages.heading}/>
          <div className="text-right"><Heading title={headingExperience}/></div>
          <div className="text-right mr-3"><Heading title={headingLevel}/></div>
        </div>
        {data.programmingLanguages.items.filter(x => x.enable).map(TechSkillsItem)}
      </div>
    );

  const IdeOrTools = () =>
    data.ideOrTools && data.ideOrTools.enable && (
      <div>
        <div className="grid grid-cols-3">
          <Heading title="IDE/Development Tools"/>
          <div className="text-right"><Heading title={headingExperience}/></div>
          <div className="text-right mr-3"><Heading title={headingLevel}/></div>
        </div>
        {data.ideOrTools.items.filter(x => x.enable).map(TechSkillsItem)}
      </div>
    );

  const DevelopmentTechnologies = () =>
    data.developmentTechnologies && data.developmentTechnologies.enable && (
      <div>
        <div className="grid grid-cols-3">
          <Heading title={data.developmentTechnologies.heading}/>
          <div className="text-right"><Heading title={headingExperience}/></div>
          <div className="text-right mr-3"><Heading title={headingLevel}/></div>
        </div>
        {data.developmentTechnologies.items.filter(x => x.enable).map(TechSkillsItem)}
      </div>
    );

  const CIcDTools = () =>
    data.ciCdTools && data.ciCdTools.enable && (
      <div>
        <div className="grid grid-cols-3">
          <Heading title={data.ciCdTools.heading}/>
          <div className="text-right"><Heading title={headingExperience}/></div>
          <div className="text-right mr-3"><Heading title={headingLevel}/></div>
        </div>
        {data.ciCdTools.items.filter(x => x.enable).map(TechSkillsItem)}
      </div>
    );

  const VersionControlSystems = () =>
    data.versionControlSystems && data.versionControlSystems.enable && (
      <div>
        <div className="grid grid-cols-3">
          <Heading title="Issue Tracking / Version Control System"/>
          <div className="text-right"><Heading title={headingExperience}/></div>
          <div className="text-right mr-3"><Heading title={headingLevel}/></div>
        </div>
        {data.versionControlSystems.items.filter(x => x.enable).map(TechSkillsItem)}
      </div>
    );

  const IOsTechnologies = () =>
    data.iOsTechnologies && data.iOsTechnologies.enable && (
      <div>
        <div className="grid grid-cols-3">
          <Heading title="iOS Technology"/>
          <div className="text-right"><Heading title={headingExperience}/></div>
          <div className="text-right mr-3"><Heading title={headingLevel}/></div>
        </div>
        {data.iOsTechnologies.items.filter(x => x.enable).map(TechSkillsItem)}
      </div>
    );

  const AndroidTechnologies = () =>
    data.androidTechnologies && data.androidTechnologies.enable && (
      <div>
        <div className="grid grid-cols-3">
          <Heading title="Android Technologies"/>
          <div className="text-right"><Heading title={headingExperience}/></div>
          <div className="text-right mr-3"><Heading title={headingLevel}/></div>
        </div>
        {data.androidTechnologies.items.filter(x => x.enable).map(TechSkillsItem)}
      </div>
    );

  const DevelopmentMethodologies = () =>
    data.developmentMethodologies && data.developmentMethodologies.enable && (
      <div>
        <div className="grid grid-cols-3">
          <Heading title="Development Methodologies"/>
          <div className="text-right"><Heading title={headingExperience}/></div>
          <div className="text-right mr-3"><Heading title={headingLevel}/></div>
        </div>
        {data.developmentMethodologies.items.filter(x => x.enable).map(TechSkillsItem)}
      </div>
    );

  const DataBases = () =>
    data.dataBases && data.dataBases.enable && (
      <div>
        <div className="grid grid-cols-3">
          <Heading title="Databases"/>
          <div className="text-right"><Heading title={headingExperience}/></div>
          <div className="text-right mr-3"><Heading title={headingLevel}/></div>
        </div>
        {data.dataBases.items.filter(x => x.enable).map(TechSkillsItem)}
      </div>
    );

  const Other = () =>
    data.other && data.other.enable && (
      <div>
        <div className="grid grid-cols-3">
          <Heading title="Other Skills"/>
          <div className="text-right"><Heading title={headingExperience}/></div>
          <div className="text-right mr-3"><Heading title={headingLevel}/></div>
        </div>
        {data.other.items.filter(x => x.enable).map(TechSkillsItem)}
      </div>
    );

  const LanguageItem = x => (
    <div key={x.id} className="grid grid-cols-2">
      <h6 className="text-sm font-medium">{x.key}</h6>
      <div className="flex">
        {x.level && <div className="text-sm mr-2">{x.level}</div>}
      </div>
    </div>
  );

  const Languages = () =>
    data.languages && data.languages.enable && (
      <div>
        <Heading title={data.languages.heading}/>
        <div className="mb-6">{data.languages.items.filter(x => x.enable).map(LanguageItem)}</div>
      </div>
    );

  const SkillItem = x => (
    <li key={x.id} className="text-sm py-1 list-disc">
      {x.value}
    </li>
  );

  const Skills = () =>
    data.skills &&
    data.skills.enable && (
      <div className="mb-6">
        <Heading title={data.skills.heading}/>
        <ul className="pl-5">{data.skills.items.map(SkillItem)}</ul>
      </div>
    );

  const KeySkillItem = x => (
    <li key={`gengar${x.id}`} className="text-sm py-1 list-disc">
      {x.title}
    </li>
  );

  const KeySkills = () =>
    data.keySkills ?
      data.keySkills.enable && (
        <div className="mb-6">
          <Heading title={data.keySkills.heading}/>
          <ul className="pl-5">{data.keySkills.items.map(KeySkillItem)}</ul>
        </div>
      ) : 
      null;

  const getValidDateAppearance = (start, end) => {
    const startDate = isValidDatePickerDate(start) ? format(new Date(start), monthYearFormat) : start;
    const endDateFormat = isValidDatePickerDate(end) ? format(new Date(end), monthYearFormat) : end;
    const endDate = end ? endDateFormat : 'Till now';
    return start && <p className="text-xs font-medium pb-1">({startDate} - {endDate})</p>
  }

  const ProjectsItems = ({x}) => (
    <>
      <div className="pt-3" />
      {(x.key || x.alternativeName) && <div className="text-sm project_desc_bg font-bold">
        {(!x.isShowName ? '' : x.key || '')
        + (x.alternativeName && x.key && x.isShowName ? ' - ' : '')
        + (x.alternativeName ? x.alternativeName : '')}
      </div>}
      {x.body && <ReactMarkdown className="text-sm project_desc_bg pb-1" source={x.body.replace(/\n/gi, '\n\n')}/>}
      {getValidDateAppearance(x.start,x.end)}
      {x.role && <div className="text-sm pb-1"><span className="font-bold text-sm">Projects Role: </span>{x.role}</div>}
      {x.type &&
      <div className="text-sm pb-1"><span className="font-bold text-sm">Application Type: </span>{x.type}</div>}
      {x.tasksExecuted && <span className="font-bold text-sm">Responsibilities: </span>}
      {x.tasksExecuted && <ReactMarkdown className="text-sm pb-1" source={x.tasksExecuted.replace(/\n/gi, '\n\n')}/>}
      {x.task_body && <span className="font-bold text-sm">Tools, Technologies: </span>}
      {x.task_body && <ReactMarkdown className="text-sm" source={x.task_body.replace(/\n/gi, '\n\n')}/>}
      <div className='pb-8 ' />
    </>
  );

  const Projects = () =>
    data.projects &&
    data.projects.enable && (
      <div>
        <p className="border-b-2 font-bold uppercase tracking-wide text-sm"
           style={{ color: theme.colors.accent, borderColor: theme.colors.accent }}
        >Projects
        </p>
        {data.projects.items.filter(x => x.enable).map((value) => <ProjectsItems key={`project${value.id}`} x={value} />)}
      </div>
    );
  return (
    <div
      style={{
        fontFamily: theme.font.family,
        backgroundColor: theme.colors.background,
        color: theme.colors.primary,
      }}
    >
      <div className="grid grid-cols-12">
        <div
          className="col-span-4 px-6 py-8 flex items-center justify-center"
          style={{ backgroundColor: theme.colors.accent, color: theme.colors.background }}
        >
          <img src={logoOfCompany} className="logo_template pointer-events-none select-none" alt="softteco"/>
        </div>

        <div
          className="col-span-8 px-6 py-8"
          style={{ backgroundColor: `rgba(${r}, ${g}, ${b}, 0.1)` }}
        >
          <div>
            <FullName/>
            <ReactMarkdown className="text-sm" source={data.summary.body.replace(/\n/gi, '\n\n')}/>
          </div>
        </div>

        <div
          className="col-span-4 px-5 py-5"
          style={{ backgroundColor: `rgba(${r}, ${g}, ${b}, 0.1)` }}
        >
          <Languages/>
          <Education/>
          <KeySkills/>
          <Skills/>
        </div>

        <div className="col-span-8 px-6 py-6">
          <p className="mb-2 border-b-2 pb-1 font-bold uppercase tracking-wide text-sm"
             style={{ color: theme.colors.accent, borderColor: theme.colors.accent }}
          >Technical Skills
          </p>
          <ProgrammingLanguages/>
          <DataBases/>
          <IdeOrTools/>
          <DevelopmentTechnologies/>
          <CIcDTools/>
          <VersionControlSystems/>
          <IOsTechnologies/>
          <AndroidTechnologies/>
          <DevelopmentMethodologies/>
          <Other/>
        </div>

        <div className="col-span-12 px-6 py-4">
          <Projects/>
        </div>
      </div>

    </div>
  );
};

export default Gengar;
