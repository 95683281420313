import React from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

const MonthYearDatePicker = ({
  name,
  value,
  onChange,
  clickResetDate,
  validationMessage=null,
  views,
  format
}) => {
  
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="w-full flex flex-col">
        <label className="uppercase tracking-wide text-gray-600 text-xs font-semibold mb-2">
          {name || "Date Picker"}
        </label>
        <div className={`w-full bg-gray-200 text-xs text-gray-400 font-semibold border 
        ${( validationMessage ) ? 'border-red-400' : 'border-gray-200'} 
        rounded py-3 px-2 leading-tight`}
        >
          <div className="w-full flex flex-row">
            <DatePicker variant="inline"
              openTo="year"
              views={views}
              value={validationMessage ? null : value}
              format={format}
              onChange={onChange}
              disableFuture
            />       

            <button
              type="button"
              onClick={clickResetDate}
              className="col-span-1 text-gray-600 hover:text-red-600 text-sm font-medium"
            >
              <div className="flex justify-center items-center">
                <i className="material-icons font-bold text-lg">close</i>
              </div>
            </button>
          </div>
        </div>
        { validationMessage  && <span className="text-red-500 text-sm block leading-1">{validationMessage}</span>}
      </div>
    </ MuiPickersUtilsProvider>
  );
};

export default MonthYearDatePicker;
