import React from 'react';

const Checkbox = ({ checked, onChange, icon = 'check', size = '2rem', label }) => {
  return (
    <div className="flex items-center">
      <div
        className="relative bg-white border-2 rounded border-gray-400 hover:border-gray-500 flex flex-shrink-0 justify-center items-center focus-within:border-blue-500 cursor-pointer"
        style={{ width: size, height: size }}
      >
        <input
          type="checkbox"
          style={{ width: size, height: size }}
          className="opacity-0 absolute cursor-pointer z-20"
          checked={checked}
          onChange={e => onChange(e.target.checked)}
        />
        <i
          className={`absolute material-icons ${
            checked ? 'opacity-100' : 'opacity-0'
          } text-sm text-gray-800`}
        >
          {icon}
        </i>
      </div>
      {label && <label className="text-sm text-gray-600 ml-2 select-none">{label}</label>}
    </div>
  );
};

export default Checkbox;
