import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import set from 'lodash/set';
import { addItem } from '../../utils';
import ItemHeading from '../ItemHeading';
import AddItemButton from '../AddItemButton';
import Form from './Form';

const AddItem = ({ heading, dispatch }) => {
  const [isOpen, setOpen] = useState(false);
  const [errors, setErrors] = useState({
    key: false,
    years: false,
  });
  const [item, setItem] = useState({
    id: uuidv4(),
    enable: true,
    key: '',
    body: '',
  });

  const onChange = (key, value) => setItem(items => set({ ...items }, key, value));

  const onSubmit = () => {
    if (item.key === '') {
      setErrors({
        key: item.key === '',
      });
      return;
    }

    addItem(dispatch, 'experience', item);

    setItem({
      id: uuidv4(),
      enable: true,
      key: '',
      body: '',
    });

    setOpen(false);
  };

  return (
    <div className="my-4 border border-gray-200 rounded">
      <ItemHeading heading={heading} setOpen={setOpen} isOpen={isOpen} />

      <div className={`${isOpen ? 'block' : 'hidden'} px-5 pb-5`}>
        <Form item={item} onChange={onChange} errors={errors} setErrors={setErrors} />

        <AddItemButton onSubmit={onSubmit} />
      </div>
    </div>
  );
};

export default AddItem;
