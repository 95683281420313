import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '../../../context/AppContext';
import Checkbox from '../../../shared/Checkbox';
import AddItem from '../../../shared/Education/AddItem';
import DragNDropList from '../../../shared/DragNDropList';
import Item from '../../../shared/Education/Item';

const EducationTab = ({ data, onChange }) => {
  const context = useContext(AppContext);
  const { dispatch } = context;
  const { t } = useTranslation('leftSidebar');

  return (
    <>
      <div className="mb23 flex justify-between items-center">
        <div className="title_tab col-span-5">{t('education.title.label')}</div>

        <div className="col-span-1">
          <Checkbox
            checked={data.education.enable}
            onChange={v => onChange('data.education.enable', v)}
          />
        </div>
      </div>

      <hr className="my-6" />

      <DragNDropList
        data={data}
        dispatch={dispatch}
        onChange={onChange}
        type="education"
      >
        {data.education.items.map((x, index) => (
          <Item
            item={x}
            key={x.id}
            index={index}
            onChange={onChange}
            dispatch={dispatch}
            first={index === 0}
            last={index === data.education.items.length - 1}
          />
        ))}        
      </DragNDropList>


      <AddItem heading={data.education.heading} dispatch={dispatch} />
    </>
  );
};

export default EducationTab;
