import React, { useRef, useContext } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import logoIco from '../assets/images/icons/softteco_logo.png';
import questionIco from '../assets/images/icons/question.png';

import AppContext from '../context/AppContext';

const TabBar = ({ tabs, currentTab, setCurrentTab, showTab, setShowTab, handleFeedBackPopup }) => {
  const tabsRef = useRef(null);

  const context = useContext(AppContext);
  const { state } = context;
  const { isSale, isAdmin } = state;

  const onTabClick = (tabKey) => {
    if (tabKey === 'feedback') {
      handleFeedBackPopup();
    } else {
      if (!showTab) setShowTab(true);
      setCurrentTab(tabKey);
    }
  };

  const toggleTabAppearance = () => setShowTab(!showTab);

  const clickMenuButton = (tabKey) => {
    if (isAdmin || isSale) toggleTabAppearance(); 
    else onTabClick(tabKey);
  }

  return (
    <div className="tab_bar">
      {isAdmin || isSale ? (
        <Tooltip title="Show / hide the edit bar">
          <Button className="tabBarLogoBlock" onClick={toggleTabAppearance}>
            <img src={logoIco} className="logo_chr" alt="" />
          </Button>
        </Tooltip>
      ) : (
        <div className="tabBarLogoBlock">
          <img src={logoIco} className="logo_chr" alt="" />
        </div>
      )}

      <hr className="mb-6 mx-2" />

      <ul id="tabs" ref={tabsRef} className="grid grid-cols-1 list-none">
        {tabs.map((tab) =>
          currentTab === tab.key ? (
            <li key={tab.key} className="mx-2 list-none mb-1">
              <Tooltip title={tab.title} placement="right">
                <div
                  className="relative bg-gray-200 text-white rounded-md text-sm flex items-center justify-center font-medium"
                  onClick={() =>clickMenuButton(tab.key)}
                >
                  {tab.icon ? (
                    <img src={tab.icon} className="tab_icon my-2" alt="tab" />
                  ) : (
                    <img src={questionIco} className="tab_icon my-2" alt="tab" />
                  )}
                  {tab.validation && <span className="notification"> </span>}
                </div>
              </Tooltip>
            </li>
          ) : (
            <li
              key={tab.key}
              className={`mx-2 list-none ${tab.key === 'feedback' ? 'feedback_tab' : ''}`}
            >
              <Tooltip title={tab.title} placement="right">
                <div
                  className="relative bg-white rounded-md cursor-pointer text-sm font-medium flex items-center justify-center hover:bg-gray-200 mb-1"
                  onClick={() => onTabClick(tab.key)}
                >
                  {tab.icon ? (
                    <img src={tab.icon} className="tab_icon my-2" alt="tab" />
                  ) : (
                    <img src={questionIco} className="tab_icon my-2" alt="tab" />
                  )}
                  {tab.validation && <span className="notification"> </span>}
                </div>
              </Tooltip>
            </li>
          ),
        )}
      </ul>
    </div>
  );
};

export default TabBar;
