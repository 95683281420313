import React from 'react';

const TextArea = ({
                    label,
                    placeholder,
                    value,
                    onChange,
                    className,
                    rows = 5,
                    classNameInput,
                    disabled = false,
                    star,
                    validation,
                    maxLength,
                  }) => (
  <div className={`w-full flex flex-col ${className}`}>
    <label
      className={`uppercase tracking-wide ${classNameInput ? 'text-gray-400' : 'text-gray-600'} text-xs font-semibold mb-2`}>
      {label}{star && <span>*</span>}
    </label>
    <div className={`relative bg-gray-200 border ${!validation ? 'border-gray-200' : 'border-red-400'} rounded focus:bg-white`}>
      <textarea
        className={`appearance-none block leading-7 w-full bg-gray-200
         ${classNameInput ? 'text-gray-400' : 'text-gray-800'} 
          py-3 px-4 focus:outline-none focus:border-gray-500 h-64`}
        rows={rows}
        value={value}
        disabled={disabled}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
        onInput={e => {
          e.target.value = e.target.value.trimStart();
        }}
      />
      {!!maxLength &&
      <span className="counter text-gray-500">{value ? value.length : 0}/{maxLength}</span>}
    </div>
  </div>
);

export default TextArea;
