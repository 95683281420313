import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AppContext from '../../../context/AppContext';
import Checkbox from '../../../shared/Checkbox';
import Item from '../../../shared/Skills/Item';
import AddItem from '../../../shared/Skills/AddItem';
import DragNDropList from '../../../shared/DragNDropList';
import KeySkillsTab from './KeySkills';

const SkillsTab = ({ data, onChange }) => {
  const context = useContext(AppContext);
  const { dispatch } = context;
  const { t } = useTranslation('leftSidebar');

  useEffect(() => {
    if (!('skills' in data)) {
      dispatch({
        type: 'migrate_section',
        payload: {
          key: 'skills',
          value: {
            enable: true,
            heading: 'skills',
            items: [],
          },
        },
      });
    }
  }, [data, dispatch]);


  return (
    <>
      <KeySkillsTab data={data} onChange={onChange} />

      <hr className="my-2" /> 
      
      <div className="my-3 flex justify-between items-center">
        <div className="title_tab">{t('skills.title.label')}</div>

        <div className="col-span-1">
          <Checkbox
            checked={data.skills.enable}
            onChange={v => onChange('data.skills.enable', v)}
          />
        </div>
      </div>

      <hr className="my-2"/>

      <div className="text-sm text-gray-600">{t('skills.tab_description.label')}</div>

      <hr className="my-2"/>

      <DragNDropList
        data={data}
        dispatch={dispatch}
        onChange={onChange}
        type="skills"
      >
        {data.skills.items.map((x, index) => (
          <Item
            item={x}
            key={x.id}
            index={index}
            onChange={onChange}
            dispatch={dispatch}
            first={index === 0}
            last={index === data.skills.items.length - 1}
          />
        ))}      
      </DragNDropList>

      <AddItem heading='Skill' dispatch={dispatch} />
    </>
  );
};

export default SkillsTab;
