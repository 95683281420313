import templates from './templates.json';
import colors from './colors.json';
import fonts from './fonts.json';
import actions from './actions.json';
import settings from './settings.json';
import about from './about.json';
import users from './users.json';

export default {
  templates,
  colors,
  fonts,
  actions,
  settings,
  about,
  users
};
