import React, { forwardRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import styles from './SortProjectsPopup.module.css';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SortProjectsPopup({open, onAgree, onDismiss}) {
  return (
    <Dialog
      open={open}
      classes={{
        root: styles.popupRoot,
        paper: styles.popupContainer,
      }}
      BackdropProps={{
        classes: {
          root: styles.backDrop,
        },
      }}
      TransitionComponent={Transition}
      keepMounted
      onClose={onDismiss}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle
        classes={{
          root: styles.popupTitle,
        }}
        id="alert-dialog-slide-title">
        Before Saving
      </DialogTitle>
      <DialogContent
        classes={{
          root: styles.popupText,
        }}>
        <p>You have updated your projects list.</p> 
        <p>Would you like to sort the projects by date?</p>
      </DialogContent>
      <DialogActions
        classes={{
          root: styles.popupControls,
        }}>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={onAgree}>
          Yes
        </Button>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={onDismiss}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
