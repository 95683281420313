import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import set from 'lodash/set';
import { get } from 'lodash';
import { addItem } from '../../utils';
import ItemHeading from '../ItemHeading';
import AddItemButton from '../AddItemButton';
import Form from './Form';

const AddItem = ({ heading, dispatch }) => {
  const [isOpen, setOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const createCleanItem = () => ({
    id: uuidv4(),
    enable: true,
    key: '',
    alternativeName: '',
    body: '',
    start: null,
    end: null,
    role: '',
    type: '',
    tasksExecuted: '',
    task_body: '',
    disabled: false,
    disabledAC: false,
    isShowName: false,
  });

  const [item, setItem] = useState(createCleanItem());

  const validateForm = (key, newMessage) => {
    if (key) {
      setValidationErrors((state) => ({ ...state, [key]: newMessage }));
      return;
    }
    let isValid = true;
    if (!item.key || !item.key.trim() || item.key.length < 3) {
      setValidationErrors((state) => ({
        ...state,
        key: 'Key field is required and can\'t be less than 3',
      }));
      isValid = false;
    }
    if (!item.alternativeName || !item.alternativeName.trim() || item.alternativeName.length < 9) {
      setValidationErrors((state) => ({
        ...state,
        alternativeName: 'Alternative Name field is required and can\'t be less than 9',
      }));
      isValid = false;
    }
    if (!item.body || !item.body.trim() || item.body.length < 80) {
      setValidationErrors((state) => ({
        ...state,
        body: 'Description field is required and can\'t be less than 80',
      }));
      isValid = false;
    }

    if (!item.start) {
      setValidationErrors((state) => ({
        ...state,
        start: 'Start Date field is required',
      }));
      isValid = false;
    }
    if (item.role) {
      if (item.role.length < 2) {
        setValidationErrors((state) => ({
          ...state,
          role: 'Role field is required and can\'t be less than 2',
        }));
        isValid = false;
      }
    }
    if (item.task_body) {
      if (item.task_body.length < 10) {
        setValidationErrors((state) => ({
          ...state,
          task_body: 'Tools and technologies field is required and can\'t be less than 10',
        }));
        isValid = false;
      }
    }
    return isValid;
  };

  const onChange = (key, value) => {
    validateForm(key, '');

    if (key === 'key') {
      value = value.substr(0, 50);
      setItem(items => set({ ...items }, 'disabledAC', value !== ''));
    }
    if (key === 'alternativeName') {
      value = value.substr(0, 67);
    }
    if (key === 'body') {
      value = value.substr(0, 600);
    }
    if (key === 'role') {
      value = value.substr(0, 50);
    }
    if (key === 'tasksExecuted') {
      value = value.substr(0, 238);
    }
    if (key === 'task_body') {
      value = value.substr(0, 240);
    }

    setItem(items => set({ ...items }, key, value));
  };

  const onProjectSelect = (itemTest) => {
    const tech = get(itemTest, 'technologies') || '' 
    setItem(items => set({ ...items }, 'alternativeName', itemTest ? get(itemTest, 'alternativeName') || '' : ''));
    setItem(items => set({ ...items }, 'key', itemTest ? get(itemTest, 'title') : ''));
    setItem(items => set({ ...items }, 'type', itemTest ? get(itemTest, 'application_type') : ''));
    setItem(items => set({ ...items }, 'body', itemTest ? get(itemTest, 'description') : ''));
    setItem(items => set({ ...items }, 'disabled', !!itemTest));
    setItem(items => set({ ...items }, 'task_body', itemTest ? tech : ''));
    if (get(itemTest, 'alternativeName') !== '' && get(itemTest, 'alternativeName') !== undefined) validateForm('alternativeName', '');
    if (get(itemTest, 'key') !== '') validateForm('key', '');
    if (get(itemTest, 'body') !== '') validateForm('body', '');
  };

  const onSubmit = () => {
    if (!validateForm()) return;

    item.key = item.key.charAt(0).toUpperCase() + item.key.slice(1);

    addItem(dispatch, 'projects', item);

    setItem(createCleanItem());

    setOpen(false);
  };

  return (
    <div className="my-4 border border-gray-200 rounded">
      <ItemHeading heading={heading} setOpen={setOpen} isOpen={isOpen}/>

      <div className={`${isOpen ? 'block' : 'hidden'} px-5 pb-5`}>
        <Form item={item} onChange={onChange} onProjectSelect={onProjectSelect} validationErrors={validationErrors}/>

        <AddItemButton onSubmit={onSubmit}/>
      </div>
    </div>
  );
};

export default AddItem;
