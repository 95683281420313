import React from 'react';
import { deleteItem } from '../utils';

const ItemHeadingWithDeleteButton = ({ isOpen, setOpen, dispatch, type, item }) => {

  return (
    <div
      className="flex justify-between items-center cursor-pointer p-5"
      onClick={() => setOpen(!isOpen)}
    >
      <h6 className="text-sm font-medium break-all whitespace-normal w-64"> 
        {item.key}
      </h6>
      <div style={{minWidth:40}}>
        <i className="material-icons">{isOpen ? 'expand_less' : 'expand_more'}</i>
        <button
          type="button"
          onClick={() => deleteItem(dispatch, type, item)}
          className="text-gray-600 pl-2 hover:text-red-600 text-sm font-medium"
        >
          <div className="flex justify-end items-center">
            <i className="material-icons font-bold text-lg">close</i>
          </div>
        </button>
      </div>
    </div>
  );
};

export default ItemHeadingWithDeleteButton;
