import { useTranslation } from 'react-i18next';
import React from 'react';

const Form = ({ item, errors }) => {
  const { t } = useTranslation('leftSidebar');
  return (
    <input
      className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${ errors.value ? 'border-red-400' : 'border-gray-200'} rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
      placeholder={t('keySkills.input.placeholder')}
      value={item.title}
      type="text"
      disabled
    />
  );
};

export default Form;
