import config from '../config';  

export const SendDataToGoogleDocs = (postData, urlTemplate, templateType) => {
  const BaseURL = `${config.http.BASE_EXPORT_URL}/api/v1/dgocs-export`;
  return fetch(BaseURL, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      'accept': '*/*',
      'accept-encoding': 'gzip, deflate',
    },
    body: JSON.stringify({ templateUrl: urlTemplate, ...postData, templateType }) })
    .then(response => (response.status !== 200 ? ({
      error: true,
    }) : response.json().then(json => json)
      .catch(() => ({
        error: true,
      }))), (error) => {
      throw new Error(error.message);
    });
};

export default SendDataToGoogleDocs;
